import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { Brand } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  getBrand(id: number): Promise<AxiosResponse<Brand>> {
    const url = `https://${baseUrl()}/brands/${id}`
    return httpService.get(url)
  },
  createBrand(payload: Brand): Promise<AxiosResponse> {
    const url = `https://${baseUrl()}/brands`
    return httpService.post(url, payload)
  },
  updateBrand(payload: Brand): Promise<AxiosResponse> {
    const url = `https://${baseUrl()}/brands/${payload.brandId}`
    return httpService.put(url, payload)
  },
  uploadAudioFile(params: { payload: any }): Promise<AxiosResponse> {
    const url = `https://${baseUrl()}/brands/uploadMP3`
    return httpService.post(url, params.payload)
  },
  brandsTableview(params: TableViewParameters): Promise<AxiosResponse<TableViewResult<Brand>>> {
    const { sorts, filters, pageSize = 10, page = 1 } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)
    const url = `https://${baseUrl()}/brands?${query}`
    return httpService.get(url)
  },
  getParentBrands(): Promise<AxiosResponse> {
    const url = `https://${baseUrl()}/brands/parentBrands`
    return httpService.get(url)
  }
}
