<template>
  <v-container fluid>
    <v-layout column sheet>
      <h1 class="page-header">Brands</h1>
      <DataTable v-bind="tableProps" />
    </v-layout>
  </v-container>
</template>

<script>
import DataTable from '@/components/DataTable.vue'
import BaseActionsRequiresWrapper from '@/components/BaseActionsRequiresWrapper.vue'
import brands from '@/services/brands'
import actionsWrapper from '@/utils/BaseActionsWrapper'

const wrappedBaseActions = actionsWrapper(
  BaseActionsRequiresWrapper,
  'brand',
  ['view', 'edit'],
  'brands'
)

const columns = [
  {
    _t_id: '001brand',
    prop: '/',
    text: 'Actions',
    component: wrappedBaseActions,
    sort: false,
    filter: false,
    detail: false,
    type: 'actions',
  },
  {
    _t_id: '002brand',
    prop: 'brandId',
    text: 'ID',
    sort: true,
    filter: true,
    detail: false,
    type: 'number',
    filterType: 'eq',
    sortProp: 'id',
    defaultSort: true,
  },
  {
    _t_id: '003brand',
    prop: 'brandName',
    text: 'Name',
    sort: true,
    filter: true,
    detail: false,
    type: 'text',
    sortProp: 'brandName',
    filterType: 'contains',
    childMethod: 'and',
  },
  {
    _t_id: 'f6e69151-5690-41c3-8dd1-75d84bf40cc3',
    prop: 'parentBrandLabel',
    text: 'Parent Brand Name',
    sort: true,
    filter: true,
    detail: false,
    type: 'text',
    sortProp: 'parentBrandLabel',
    filterType: 'contains',
    childMethod: 'and',
  }
]

export default {
  components: {
    DataTable,
  },
  metaInfo() {
    return {
      title: 'Brands',
    }
  },
  data() {
    return {
      debounce: undefined,
      filters: () => [],
      sorts: () => [],
      user: {},
      itemsPerPage: 10,
      page: 1,
      isAdmin: false,
      tableProps: {
        enableExport: false,
        enableColumnConfig: false,
        enableSavedViews: false,
        total: 0,
        tableId: 'brands_table_view',
        currentPage: 1,
        perPage: 10,
        list: [],
        sort: this.sort,
        changePage: this.changePage,
        addFilter: this.addFilter,
        removeFilter: this.removeFilter,
        addNewEnabled: true,
        addNewHandler: () => this.$router.push({ name: 'brands.add' }),
        setSort: this.setSort,
        detailKeyId: 'brandId',
        isDetailed: false,
        shareFilters: this.receiveFilters,
        shareSorts: this.receiveSorts,
        loading: true,
        isAdmin: this.isAdmin,
        columns,
      },
    }
  },
  methods: {
    receiveFilters(filters) {
      this.filters = filters
    },
    receiveSorts(sorts) {
      this.sorts = sorts
    },
    addFilter() {
      this.currentPage = 1
      this.refresh()
    },
    removeFilter() {
      this.currentPage = 1
      this.refresh(true)
    },
    refresh(immediate) {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      if (immediate) {
        this.getTable()
      } else {
        this.debounce = setTimeout(this.getTable, 500)
      }
    },
    async getTable() {
      const sorts = this.sorts.asQueryParams()
      const filters = this.filters.asQueryParams()
      const params = {
        sorts,
        filters,
        pageSize: this.itemsPerPage,
        page: this.currentPage,
      }
      this.tableProps.loading = true
      const brandsResponse = await brands.brandsTableview(params)
      const { resultList, count } = brandsResponse.data
      this.tableProps.perPage = this.itemsPerPage
      this.tableProps.currentPage = this.currentPage
      this.tableProps.list = resultList
      this.tableProps.total = count
      this.tableProps.loading = false
    },
    sort(sortItem) {
      this.sorts.add(sortItem)
      this.refresh(true)
    },
    changePage(pagination) {
      this.currentPage = pagination.page
      this.itemsPerPage = pagination.rowsPerPage
      this.refresh()
    },
  },
}
</script>
